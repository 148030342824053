'use client';

import { IReviewComponent } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import AnimatedTitle from '../../../components/AnimatedTitle';
import ClutchReviews from './ClutchReviews';
import styles from './Reviews.module.scss';
import ReviewsTextSlider from './ReviewsTextSlider';
import ReviewsVideos from './ReviewsVideos';

const Reviews = ({ data }: { data: IReviewComponent }) => (
  <section className={classnames(styles.reviews, styles[`${data.view}-view`])}>
    <header className={classnames(styles.reviews__header, 'container')}>
      <h2 className={classnames('sectionTitle', styles.title)}>
        <AnimatedTitle title={data?.title} />
      </h2>
      <p className={styles.text}>
        {data?.description}
      </p>
    </header>
    <div className={styles.reviews__content}>
      {data.showVideoReviews && (
      <ReviewsVideos
        data={data?.videoReviews}
        view={data.view}
      />
      )}
      {data.showTextReviews && <ReviewsTextSlider data={data?.textReviews} />}
      {data.showClutch && data?.clutch && <ClutchReviews data={data.clutch} />}
    </div>
  </section>
);

export default Reviews;
