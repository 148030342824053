import { useEffect, useRef, useState } from 'react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { IReviewVideo } from '@sapientpro/sapientpro-data-models';
import PaginationButton, { PaginationItem } from './PaginationButton';
import styles from './ReviewsVideos.module.scss';

interface IPaginationSliderProps {
  data: IReviewVideo[];
  handleChange: (index: number) => void;
}

const PaginationSlider = ({ data, handleChange }:IPaginationSliderProps) => {
  const [paginationItems, setPaginationItems] = useState<PaginationItem[]>([]);
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);
  const sliderRef = useRef<Splide>(null);

  useEffect(() => {
    if (data.length) {
      const newPaginationButtons = data.map((item, index) => ({
        title: `review-${index}`,
        content: 'review content',
      }));
      setPaginationItems(newPaginationButtons);
    }
  }, [data]);

  return (
    <Splide
      hasTrack={false}
      ref={sliderRef}
      className={styles.paginationSlider}
      options={{
        arrows: false,
        type: 'slide',
        pagination: false,
        gap: 16,
        autoWidth: true,
        perMove: 1,
        height: '88px',
      }}
    >
      <SplideTrack>
        {paginationItems.map((item, index) => (
          <SplideSlide
            key={item.title}
            style={{ pointerEvents: 'auto' }}
          >
            <PaginationButton
              item={item}
              handleChange={() => {
                setActiveItemIndex(index);
                handleChange(index);
                sliderRef.current?.splide?.go(index);
              }}
              isActive={activeItemIndex === index}
            />
          </SplideSlide>
        ))}
      </SplideTrack>
    </Splide>
  );
};

export default PaginationSlider;
