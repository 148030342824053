import { IReviewVideo, ViewValues } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import React, { RefObject } from 'react';
import { useAtomValue } from 'jotai';
import Img from '../../../../../components/Img';
import ReviewAuthor from '../../ReviewAuthor';
import styles from './ReviewsVideo.module.scss';
import { theme } from '../../../../../store/theme';

interface ReviewsVideoProps {
  className: string,
  review: IReviewVideo,
  controlVideo: (command: string, ref: RefObject<HTMLIFrameElement>) => void,
  showVideo: boolean,
  setShowVideo: (value: boolean) => void,
  view: ViewValues,
}

const ReviewsVideo = React.forwardRef<HTMLIFrameElement, ReviewsVideoProps>(({
  className, review, controlVideo, showVideo, setShowVideo, view,
}, ref) => {
  const appTheme = useAtomValue(theme);

  return (
    <div
      className={classnames(styles.review, styles[`${view}-view`], className)}
    >
      {!showVideo ? (
        <button
          type='button'
          onClick={() => {
            setShowVideo(true);
          }}
        >
          <Img
            src={review?.clipPreviewImage?.url}
            alt={review.clipPreviewImage.alternativeText || 'review video preview'}
            className={styles.preview}
          />
          <ReviewAuthor
            avatar={review?.author?.avatar}
            name={review?.author?.name}
            position={review?.author?.position}
            className={styles.author}
            photoClassName={styles.author__avatar}
            nameClassName={styles.author__name}
            positionClassName={styles.author__position}
          />
        </button>
      ) : (
        <div
          className={styles.video}
        >
          <iframe
            ref={ref}
            width='950'
            height='534'
            src={`${review.clip}?rel=0&enablejsapi=1`}
            title={`${review.author.name} feedback`}
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowFullScreen
            onLoad={() => controlVideo('playVideo', ref as RefObject<HTMLIFrameElement>)}
          />
        </div>
      )}
      <div className={styles.reviewContent}>
        <div className={styles.reviewText}>
          <svg className={styles.reviewIcon}>
            <use
              href='media/quote-icon.svg#quote-icon'
              xlinkHref='media/quote-icon.svg#quote-icon'
            />
          </svg>
          <p>
            <span>
              We aim to help our clients build successful products,
              improve business processes, and provide a competitive advantage.
              Our software development company has proven to be a reliable partner by receiving a 4.9/5 score on Clutch.co.
            </span>
          </p>
        </div>
        <div className={styles.reviewFooter}>
          <ReviewAuthor
            avatar={review?.author?.avatar}
            name={review?.author?.name}
            position={review?.author?.position}
            className={classnames(styles.author, styles[`${view}-view`])}
            photoClassName={styles.author__avatar}
            nameClassName={styles.author__name}
            positionClassName={styles.author__position}
          />
          <div
            className={styles.author__clutch}
          >
            <Img
              src={`/media/clutch_${appTheme}.svg`}
              alt='clutch'
              width={43}
              height={12}
              className={styles.logo}
            />
            Based
          </div>
        </div>
        <div className={styles.blur} />
      </div>
    </div>
  );
});

export default React.memo(ReviewsVideo);
